import { errorPrefix } from "tsparticles-engine";
import { replaceImageColor } from "./Utils";
export class ImageDrawer {
    constructor(engine) {
        this.loadImageShape = async (imageShape) => {
            if (!this._engine.loadImage) {
                throw new Error(`${errorPrefix} image shape not initialized`);
            }
            await this._engine.loadImage({
                gif: imageShape.gif,
                name: imageShape.name,
                replaceColor: imageShape.replaceColor ?? imageShape.replace_color ?? false,
                src: imageShape.src,
            });
        };
        this._engine = engine;
    }
    addImage(image) {
        if (!this._engine.images) {
            this._engine.images = [];
        }
        this._engine.images.push(image);
    }
    draw(context, particle, radius, opacity, delta) {
        const image = particle.image, element = image?.element;
        if (!image) {
            return;
        }
        context.globalAlpha = opacity;
        if (image.gif && image.gifData) {
            const offscreenCanvas = new OffscreenCanvas(image.gifData.width, image.gifData.height), offscreenContext = offscreenCanvas.getContext("2d");
            if (!offscreenContext) {
                throw new Error("could not create offscreen canvas context");
            }
            offscreenContext.imageSmoothingQuality = "low";
            offscreenContext.imageSmoothingEnabled = false;
            offscreenContext.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);
            if (particle.gifLoopCount === undefined) {
                particle.gifLoopCount = image.gifLoopCount ?? 0;
            }
            let frameIndex = particle.gifFrame ?? 0;
            const pos = { x: -image.gifData.width * 0.5, y: -image.gifData.height * 0.5 }, frame = image.gifData.frames[frameIndex];
            if (particle.gifTime === undefined) {
                particle.gifTime = 0;
            }
            if (!frame.bitmap) {
                return;
            }
            context.scale(radius / image.gifData.width, radius / image.gifData.height);
            switch (frame.disposalMethod) {
                case 4:
                case 5:
                case 6:
                case 7:
                case 0:
                    offscreenContext.drawImage(frame.bitmap, frame.left, frame.top);
                    context.drawImage(offscreenCanvas, pos.x, pos.y);
                    offscreenContext.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);
                    break;
                case 1:
                    offscreenContext.drawImage(frame.bitmap, frame.left, frame.top);
                    context.drawImage(offscreenCanvas, pos.x, pos.y);
                    break;
                case 2:
                    offscreenContext.drawImage(frame.bitmap, frame.left, frame.top);
                    context.drawImage(offscreenCanvas, pos.x, pos.y);
                    offscreenContext.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);
                    if (image.gifData.globalColorTable.length === 0) {
                        offscreenContext.putImageData(image.gifData.frames[0].image, pos.x + frame.left, pos.y + frame.top);
                    }
                    else {
                        offscreenContext.putImageData(image.gifData.backgroundImage, pos.x, pos.y);
                    }
                    break;
                case 3:
                    {
                        const previousImageData = offscreenContext.getImageData(0, 0, offscreenCanvas.width, offscreenCanvas.height);
                        offscreenContext.drawImage(frame.bitmap, frame.left, frame.top);
                        context.drawImage(offscreenCanvas, pos.x, pos.y);
                        offscreenContext.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);
                        offscreenContext.putImageData(previousImageData, 0, 0);
                    }
                    break;
            }
            particle.gifTime += delta.value;
            if (particle.gifTime > frame.delayTime) {
                particle.gifTime -= frame.delayTime;
                if (++frameIndex >= image.gifData.frames.length) {
                    if (--particle.gifLoopCount <= 0) {
                        return;
                    }
                    frameIndex = 0;
                    offscreenContext.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);
                }
                particle.gifFrame = frameIndex;
            }
            context.scale(image.gifData.width / radius, image.gifData.height / radius);
        }
        else if (element) {
            const ratio = image.ratio, pos = {
                x: -radius,
                y: -radius,
            };
            context.drawImage(element, pos.x, pos.y, radius * 2, (radius * 2) / ratio);
        }
        context.globalAlpha = 1;
    }
    getSidesCount() {
        return 12;
    }
    async init(container) {
        const options = container.actualOptions;
        if (!options.preload || !this._engine.loadImage) {
            return;
        }
        for (const imageData of options.preload) {
            await this._engine.loadImage(imageData);
        }
    }
    loadShape(particle) {
        if (particle.shape !== "image" && particle.shape !== "images") {
            return;
        }
        if (!this._engine.images) {
            this._engine.images = [];
        }
        const imageData = particle.shapeData, image = this._engine.images.find((t) => t.name === imageData.name || t.source === imageData.src);
        if (!image) {
            this.loadImageShape(imageData).then(() => {
                this.loadShape(particle);
            });
        }
    }
    particleInit(container, particle) {
        if (particle.shape !== "image" && particle.shape !== "images") {
            return;
        }
        if (!this._engine.images) {
            this._engine.images = [];
        }
        const images = this._engine.images, imageData = particle.shapeData, color = particle.getFillColor(), image = images.find((t) => t.name === imageData.name || t.source === imageData.src);
        if (!image) {
            return;
        }
        const replaceColor = imageData.replaceColor ?? imageData.replace_color ?? image.replaceColor;
        if (image.loading) {
            setTimeout(() => {
                this.particleInit(container, particle);
            });
            return;
        }
        (async () => {
            let imageRes;
            if (image.svgData && color) {
                imageRes = await replaceImageColor(image, imageData, color, particle);
            }
            else {
                imageRes = {
                    color,
                    data: image,
                    element: image.element,
                    gif: image.gif,
                    gifData: image.gifData,
                    gifLoopCount: image.gifLoopCount,
                    loaded: true,
                    ratio: imageData.width && imageData.height ? imageData.width / imageData.height : image.ratio ?? 1,
                    replaceColor: replaceColor,
                    source: imageData.src,
                };
            }
            if (!imageRes.ratio) {
                imageRes.ratio = 1;
            }
            const fill = imageData.fill ?? particle.fill, close = imageData.close ?? particle.close, imageShape = {
                image: imageRes,
                fill,
                close,
            };
            particle.image = imageShape.image;
            particle.fill = imageShape.fill;
            particle.close = imageShape.close;
        })();
    }
}
