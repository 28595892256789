import { downloadSvgImage, loadGifImage, loadImage } from "./Utils";
import { ImageDrawer } from "./ImageDrawer";
import { ImagePreloaderPlugin } from "./ImagePreloader";
import { errorPrefix } from "tsparticles-engine";
function addLoadImageToEngine(engine) {
    if (engine.loadImage) {
        return;
    }
    engine.loadImage = async (data) => {
        if (!data.name && !data.src) {
            throw new Error(`${errorPrefix} no image source provided`);
        }
        if (!engine.images) {
            engine.images = [];
        }
        if (engine.images.find((t) => t.name === data.name || t.source === data.src)) {
            return;
        }
        try {
            const image = {
                gif: data.gif ?? false,
                name: data.name ?? data.src,
                source: data.src,
                type: data.src.substring(data.src.length - 3),
                error: false,
                loading: true,
                replaceColor: data.replaceColor,
                ratio: data.width && data.height ? data.width / data.height : undefined,
            };
            engine.images.push(image);
            const imageFunc = data.gif ? loadGifImage : data.replaceColor ? downloadSvgImage : loadImage;
            await imageFunc(image);
        }
        catch {
            throw new Error(`${errorPrefix} ${data.name ?? data.src} not found`);
        }
    };
}
export async function loadImageShape(engine, refresh = true) {
    addLoadImageToEngine(engine);
    const preloader = new ImagePreloaderPlugin(engine);
    await engine.addPlugin(preloader, refresh);
    await engine.addShape(["image", "images"], new ImageDrawer(engine), refresh);
}
