export class ByteStream {
    constructor(bytes) {
        this.pos = 0;
        this.data = new Uint8ClampedArray(bytes);
    }
    getString(count) {
        const slice = this.data.slice(this.pos, this.pos + count);
        this.pos += slice.length;
        return slice.reduce((acc, curr) => acc + String.fromCharCode(curr), "");
    }
    nextByte() {
        return this.data[this.pos++];
    }
    nextTwoBytes() {
        this.pos += 2;
        return this.data[this.pos - 2] + (this.data[this.pos - 1] << 8);
    }
    readSubBlocks() {
        let blockString = "", size = 0;
        do {
            size = this.data[this.pos++];
            for (let count = size; --count >= 0; blockString += String.fromCharCode(this.data[this.pos++])) {
            }
        } while (size !== 0);
        return blockString;
    }
    readSubBlocksBin() {
        let size = 0, len = 0;
        for (let offset = 0; (size = this.data[this.pos + offset]) !== 0; offset += size + 1) {
            len += size;
        }
        const blockData = new Uint8Array(len);
        for (let i = 0; (size = this.data[this.pos++]) !== 0;) {
            for (let count = size; --count >= 0; blockData[i++] = this.data[this.pos++]) {
            }
        }
        return blockData;
    }
    skipSubBlocks() {
        for (; this.data[this.pos] !== 0; this.pos += this.data[this.pos] + 1) {
        }
        this.pos++;
    }
}
